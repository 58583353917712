import React, { useState, useEffect } from "react";
import { Gantt } from "gantt-task-react";
import moment from "moment/moment";
import "gantt-task-react/dist/index.css";
import { navigate } from "gatsby";
import { useAdminState } from "../../context/AdminContext";
import getProductionList from "../../services/getProductionList";
import Loading from "../../shared/Loading";
import { Container, Paper } from "@mui/material";
import Breadcrumb from "../../shared/Breadcrumbs";

function Roadmap() {
  const { loading, productionList } = useAdminState().productionList;
  const [projectData, setProjectData] = useState([]);

  useEffect(() => {
    getProductionList("2023,2024", "all");
  }, []);

  useEffect(() => {
    setProjectData(productionList?.data || []);
  }, [productionList]);

  const InProgressData = projectData.filter(
    (i) =>
      (i.stage === "Ready to Develop" || i.stage === "Implementing") &&
      (i.startDate !== null || i.finishDate !== null)
  );

  const tasks = InProgressData.map((i) => {
    return {
      start: new Date(moment(i.startDate, "DD/MM/YYYY").format("YYYY, MM, DD")),
      end: new Date(moment(i.finishDate, "DD/MM/YYYY").format("YYYY, MM, DD")),
      name: `[${i["projectId"].slice(-3)}] ${i.projectName}`,
      id: i.projectId,
      type: "task",
      progress: null,
      isDisabled: false,
    };
  });

  const onClickProject = ({ id }) => {
    navigate(`/project-details?id=${id}`);
  };

  const TaskListComlum = ({ tasks }) => {
    return (
      <div>
        {tasks.map((item) => {
          return (
            <div className="gantt-project">
              <div>{item.name}</div>
            </div>
          );
        })}
      </div>
    );
  };

  const TaskListHeader = () => {
    return (
      <div className="gantt-header">
        <div style={{ paddingLeft: "1rem" }} />
        <div>Project</div>
      </div>
    );
  };

  return (
    <div className="dashboard roadmap">
      <div className="page-header">
        <h2>Project Roadmap</h2>
        <div className="flex">
          <Breadcrumb page="Project Roadmap" link="roadmap" />
        </div>
      </div>

      {loading ? (
        <Loading />
      ) : tasks.length > 0 ? (
        <div className="roadmap">
          <Gantt
            tasks={tasks}
            viewMode="Month"
            viewDate={new Date()}
            todayColor="rgba(0, 188, 180, 0.4)"
            TaskListTable={TaskListComlum}
            TaskListHeader={TaskListHeader}
            onClick={onClickProject}
          />
        </div>
      ) : (
        <Container component="main" maxWidth="sm">
          <Paper>
            <h4>No Project Found!</h4>
          </Paper>
        </Container>
      )}
    </div>
  );
}

export default Roadmap;
