import React from "react";
import Roadmap from "../components/Roadmap";
import Seo from "../components/seo";

const RoadMapPage = (props) => {
  return (
    <>
      <Seo title="Project Roadmap" />
      <Roadmap {...props} />
    </>
  );
};

export default RoadMapPage;
